import React, { useState } from 'react';
import webinarImage from './Webinar_Poster_HeatExchanger.jpg';
import './Webinars.css';
import Testimonials from './Testimonials';
import { ref, set } from "firebase/database";
import { database } from '../firebase';


const Webinars = () => {
  
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [education, setEducation] = useState('');
  const [organization, setOrganization] = useState('');
  const [workExperience, setWorkExperience] = useState('');
  const [workingField, setWorkingField] = useState('');
  const [jobType, setJobType] = useState('');
  const [aspenExperience, setAspenExperience] = useState('');
  const [hearAboutUs, setHearAboutUs] = useState('');
  const [note, setNote] = useState('');
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const forbiddenChars = /[$#\[\]\/]/; // Restrict $, #, [, ], /

  const handleInputChange = (setter) => (event) => {
    const value = event.target.value;
    if (forbiddenChars.test(value)) {
      return; // Prevent input if forbidden characters are found
    }
    setter(value);
  };

  const validateForm = () => {
    const newErrors = {};
    const phonePattern = /^[0-9]{10}$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!name.trim()) newErrors.name = 'Name is required';
    if (!phonePattern.test(phone)) newErrors.phone = 'Phone number must be 10 digits';
    if (!emailPattern.test(email)) newErrors.email = 'Invalid email address';
    if (!education) newErrors.education = 'Highest education is required';
    if (!organization.trim()) newErrors.organization = 'Current college/organization is required';
    if (!hearAboutUs) newErrors.hearAboutUs = 'This field is required';
    if (!workExperience) newErrors.workExperience = 'Work experience is required';
    if (!workingField) newErrors.workingField = 'Working field is required';
    if (!jobType) newErrors.jobType = 'Job type is required';
    if (!aspenExperience) newErrors.aspenExperience = 'Experience in Aspen Plus is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

    // Function to handle link sharing
    const handleShare = () => {
      if (navigator.share) {
        navigator.share({
          title: 'Check out this webinar',
          text: 'Join this amazing webinar to boost your career!',
          url: window.location.href
        }).then(() => {
          console.log('Link shared successfully');
        }).catch((error) => {
          console.error('Error sharing the link:', error);
        });
      } else {
        alert('Your browser does not support the Web Share API.');
      }
    };



  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const formData = {
      name,
      phone,
      email,
      education,
      organization,
      workExperience,
      workingField,
      jobType,
      aspenExperience,
      hearAboutUs,
      note,
    };

    // Save form data to Firebase
    set(ref(database, 'webinarRegistrations/HeatExchanger/06032025/' + new Date().getTime()), formData)
      .then(() => {
        console.log('Form data saved to Firebase successfully');
        setShowPopup(true); // Show success popup
        setTimeout(() => setShowPopup(false), 3000); // Hide after 3 sec
      })
      .catch((error) => {
        console.error("Error saving form data to Firebase:", error);
      });

    // Clear the form fields
    setName('');
    setPhone('');
    setEmail('');
    setEducation('');
    setOrganization('');
    setWorkExperience('');
    setWorkingField('');
    setJobType('');
    setAspenExperience('');
    setHearAboutUs('');
    setNote('');
    setErrors({});
  };

  return (
    <div className="webinars">
      <h1>Boost Your Career with Webinars & Masterclasses</h1>
      <h2>About Synergyy Tech</h2>
      <p>
        Join webinar & master sessions on different industrial topics. Get to know about different industrial innovations & technological upgradation. Interact with industrial professionals & resolve your doubts on different industrial designing concepts.
      </p>
      <div className="webinar-content">
        <div className="webinar-image">
          <img src={webinarImage} alt="Online Webinar" />
        </div>
        <div className="webinar-form">
          <h3>Enroll here to register for webinar</h3>
          <button onClick={handleShare}>Share This Webinar</button> {/* New Share Button */}

          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name (Required)</label>
              <input 
                type="text" 
                id="name" 
                name="name" 
                value={name} 
                onChange={handleInputChange(setName)} 
                required 
              />
              {errors.name && <span className="error">{errors.name}</span>}              
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone (Required)</label>
              <input 
                type="tel" 
                id="phone" 
                name="phone" 
                value={phone} 
                onChange={handleInputChange(setPhone)} 
                required 
              />
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email (Required)</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                value={email} 
                onChange={handleInputChange(setEmail)} 
                required 
              />
             {errors.email && <span className="error">{errors.email}</span>}

            </div>
            <div className="form-group">
              <label htmlFor="education">Highest Education (Required)</label>
              <select 
                id="education" 
                name="education" 
                value={education} 
                onChange={handleInputChange(setEducation)} 
                required
              >
                <option value="">Select</option>
                <option value="B.Tech">B.Tech</option>
                <option value="M.Tech">M.Tech</option>
                <option value="PhD">PhD</option>
                {/* Add more options as needed */}
              </select>
              {errors.education && <span className="error">{errors.education}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="organization">Current College/Organization (Required)</label>
              <input 
                type="text" 
                id="organization" 
                name="organization" 
                value={organization} 
                onChange={handleInputChange(setOrganization)} 
                required 
              />
              {errors.organization && <span className="error">{errors.organization}</span>}
            </div>

             {/* New fields */}
             <div className="form-group">
              <label htmlFor="workExperience">Work Experience (Required)</label>
              <select 
                id="workExperience" 
                name="workExperience" 
                value={workExperience} 
                onChange={handleInputChange(setWorkExperience)} 
                required
              >
                <option value="">Select</option>
                <option value="Less than 1 year">Less than 1 year</option>
                <option value="1-2 years">1-2 years</option>
                <option value="3-5 years">3-5 years</option>
                <option value="5+ years">5+ years</option>
              </select>
              {errors.workExperience && <span className="error">{errors.workExperience}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="workingField">Working Field (Required)</label>
              <select 
                id="workingField" 
                name="workingField" 
                value={workingField} 
                onChange={handleInputChange(setWorkingField)} 
                required
              >
                <option value="">Select</option>
                <option value="Pharma">Pharma</option>
                <option value="Chemical Manufacturing">Chemical Manufacturing</option>
                <option value="Oil & Gas">Oil & Gas</option>
                <option value="Petrochemicals">Petrochemicals</option>
                <option value="Fertilizers">Fertilizers</option>
                <option value="Polymers & Plastics">Polymers & Plastics</option>
                <option value="Food & Beverages">Food & Beverages</option>
                <option value="Water Treatment">Water Treatment</option>
                <option value="Energy & Utilities">Energy & Utilities</option>
                <option value="Consulting & Engineering Services">Consulting & Engineering Services</option>
                <option value="R&D">R&D</option>
                <option value="Academic">Academic</option>
              </select>
              {errors.workingField && <span className="error">{errors.workingField}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="jobType">Type of Job (Required)</label>
              <select 
                id="jobType" 
                name="jobType" 
                value={jobType} 
                onChange={handleInputChange(setJobType)} 
                required
              >
                <option value="">Select</option>
                <option value="Production">Production</option>
                <option value="Process Engineering">Process Engineering</option>
                <option value="Design Engineering">Design Engineering</option>
                <option value="Project Engineering">Project Engineering</option>
                <option value="R&D">R&D</option>
                <option value="Quality Control">Quality Control</option>
                <option value="Operations">Operations</option>
                <option value="Technical Sales/Marketing">Technical Sales/Marketing</option>
              </select>
              {errors.jobType && <span className="error">{errors.jobType}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="aspenExperience">Years of Experience in Aspen Plus (Required)</label>
              <select 
                id="aspenExperience" 
                name="aspenExperience" 
                value={aspenExperience} 
                onChange={handleInputChange(setAspenExperience)} 
                required
              >
                <option value="">Select</option>
                <option value="Less than 1 year">Less than 1 year</option>
                <option value="1-2 years">1-2 years</option>
                <option value="3-5 years">3-5 years</option>
                <option value="5+ years">5+ years</option>
              </select>
              {errors.aspenExperience && <span className="error">{errors.aspenExperience}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="hear-about-us">Where did you hear about us (Required)</label>
              <select 
                id="hear-about-us" 
                name="hear-about-us" 
                value={hearAboutUs} 
                onChange={handleInputChange(setHearAboutUs)} 
                required
              >
                <option value="">Select</option>
                <option value="Google">Google</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Friend">Friend</option>
                {/* Add more options as needed */}
              </select>
              {errors.hearAboutUs && <span className="error">{errors.hearAboutUs}</span>}

            </div>
            {/* <div className="form-group">
              <label htmlFor="note">Note to us</label>
              <textarea 
                id="note" 
                name="note" 
                rows="4" 
                value={note} 
                onChange={handleInputChange(setNote)}
              ></textarea>
            </div> */}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <p>✅ Form submitted successfully!</p>
        </div>
      )}

      <style>{`
        .popup {
          position: fixed;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #4CAF50;
          color: white;
          padding: 15px;
          border-radius: 5px;
          box-shadow: 0 4px 6px rgba(0,0,0,0.1);
          z-index: 1000;
        }
      `}</style>
      <div className="training-perks">
        <h2>Training Perks</h2>
        <ul>
          <li>Live sessions</li>
          <li>Expert certified trainers</li>
          <li>Best courses in industry</li>
          <li>Recorded lectures on website</li>
          <li>Weekly test & practice sessions</li>
          <li>Hand on practice</li>
          <li>Doubt sessions</li>
          <li>Internship opportunities</li>
          <li>Programs suitable for anyone</li>
          <li>Worldwide available</li>
          <li>Interact with industry experts</li>
          <li>Get exposure to practical Engineering concepts</li>
          <li>All industrial software on single platform</li>
        </ul>
      </div>
      <Testimonials />
    </div>

  );
};

export default Webinars;
